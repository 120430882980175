import * as React from "react";
import Seo from "gatsby-plugin-wpgraphql-seo";

import { getCurrentPageSeo } from "../helpers/richContentHelpers";
import { infinityOverviewPageTplData } from "../data/page-tpl-infinity-overview.data";

import Layout from "../components/Layout";
import Section from "../components/Section";
import Hero from "../componentsSections/Hero";
import GetInTouch from "../components/GetInTouch";
import NewsAndInsights from "../components/NewsAndInsights";
import ImageSection from "../components/ImageSection";
import ImageSectionsGroup from "../components/ImageSectionsGroup";
import CardsSection from "../components/CardsSection";
import ConnectivityLogos from "../components/ConnectivityLogos";
import { VideoSection } from "../components/VideoSection";

const Page = ({ pageContext: { id } }) => {
  const seoPageData = getCurrentPageSeo(id);
  const pageData = infinityOverviewPageTplData(id);

  return (
    <>
      <Layout navbarVariant={pageData.hero.image ? "transparent" : undefined}>
        {/* SEO Metas */}
        <Seo post={seoPageData} />

        {/* Page head */}
        <Hero {...pageData.hero} textSize="big" />

        {/* Cards Sections */}
        <Section>
          <CardsSection {...pageData.cards} />
        </Section>

        {/* Video Section */}
        <Section className="-mobile-full">
          <VideoSection
            containerClassName="-full-width pt-0 pb-5 px-0"
            video={pageData.video}
          />
        </Section>

        {/* Full-width Image Section */}
        <Section className="-mobile-full">
          <ImageSection {...pageData.fullWidthImage} />
        </Section>

        {/* Image Sections */}
        <Section className="-mobile-full">
          <ImageSectionsGroup imageSections={pageData.imageSections} />
        </Section>

        {/* Logos Section */}
        <Section>
          <ConnectivityLogos
            containerClassName="pt-0"
            {...pageData.logosSection}
          />
        </Section>

        {/* Single Image Section */}
        <ImageSection
          containerClassName="pt-2"
          contentClassName="pt-3"
          imageSection={pageData.imageSection}
        />

        {/* GetInTouch Section */}
        <Section className="-mobile-full">
          <GetInTouch />
        </Section>

        {/* News and insights Section */}
        <Section>
          <NewsAndInsights />
        </Section>
      </Layout>
    </>
  );
};

export default Page;
