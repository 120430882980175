import React, { useEffect, useRef } from "react";
import GenericWideSection from "./GenericWideSection";
import { fixURL } from "../helpers/urlHelpers";

export const VideoSection = ({ title, video, ...props }) => {
  const videoRef = useRef();

  useEffect(() => {
    if (!videoRef.current) return;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      },
      { threshold: 0.6 }
    );

    observer.observe(videoRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <GenericWideSection
      //   header={title}
      smallContent={false}
      contentClasName="-wide"
      containerClassName={"-full-width"}
      vertical
      {...props}
    >
      <video
        playsInline
        muted
        loop
        ref={videoRef}
        style={{
          // maxHeight: "700px",
          maxWidth: "100%",
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
      >
        {video.sources.map(({ source }) => (
          <source
            key={source.id}
            src={fixURL(source.localFile.publicURL)}
            type={source.mimeType}
          />
        ))}
      </video>
    </GenericWideSection>
  );
};
